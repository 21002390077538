function BaseCard({ ship }) {
  return (
    <div id="base" className={`shipBase ${ship.race} ${ship.size}`}>
      {Object.keys(ship?.points).map((point) =>
        [
          "attack",
          "attackLeft",
          "attackRight",
          "attack45",
          "attackBull",
        ].includes(point) ? (
          <div key={point} className={point}></div>
        ) : null
      )}
      {ship?.upgrades?.map(
        (upgrade) =>
          upgrade.plus &&
          upgrade.plus.points &&
          Object.keys(upgrade?.plus?.points).map((point) =>
            ["attack45"].includes(point) ? (
              <div key={point}>
                <hr className={`${point}Left`} />
                <hr className={`${point}Right`} />
              </div>
            ) : null
          )
      )}
      {Object.keys(ship?.points).includes("attack") ? null : (
        <div className="frontArc"></div>
      )}
      {Object.keys(ship?.points).includes("attackBull") ? null : (
        <div className="bullArc"></div>
      )}

      <div className="backArc"></div>

      <hr className="crossVertical" />
      <hr className="crossHorisontal" />
      <div className="baseName">{ship.name}</div>
    </div>
  );
}

export default BaseCard;
