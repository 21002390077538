import { useParams } from "react-router-dom";
import "./fleet.css";
import ShipsInner from "../ships/shipsInner";
import Cards from "../cards/cards";
import { useState } from "react";
import UnitCard from "../unitCard/unitCard";
import terranShips from "../../mockups/terranShips.json";
import zergShips from "../../mockups/zergShips.json";
import protossShips from "../../mockups/protossShips.json";
import UpgradeCard from "../UpgradeCard/UpgradeCard";

function Fleet({ fleets, races }) {
  const ships = {
    protoss: protossShips,
    terran: terranShips,
    zerg: zergShips,
  };
  function selectRace(race) {
    !races.includes(fleet.race) && setFleet({ ...fleet, race: race });
  }
  let { fleetName } = useParams();

  const [fleet, setFleet] = useState(
    fleetName === "new"
      ? { name: "", points: 0, maxPoints: 100, race: "", ships: [] }
      : fleets.filter((f) => f.name === fleetName)[0]
  );
  function removeCard(i) {
    setFleet({
      ...fleet,
      ships: [...fleet.ships.filter((ship, j) => j !== i)],
    });
  }
  function removeUpgCard(s, u) {
    setFleet({
      ...fleet,
      ships: [
        ...fleet.ships.map((ship, i) =>
          s !== i
            ? { ...ship }
            : { ...ship, upgs: ship.upgs.filter((upg, j) => j !== u) }
        ),
      ],
    });
  }
  function changeMax(e) {
    setFleet({
      ...fleet,
      maxPoints: e.target.value,
    });
  }
  function changeName(e) {
    setFleet({
      ...fleet,
      name: e.target.value,
    });
  }
  function addUpg(shipId, upgId) {
    !!!fleet.ships[shipId].upgs.filter((upg) => upg.img_name === upgId)
      .length &&
      setFleet({
        ...fleet,
        ships: [
          ...fleet.ships.map((ship, i) =>
            shipId !== i
              ? { ...ship }
              : {
                  ...ship,
                  upgs: [
                    ...ship.upgs,
                    {
                      img_name: getShip(ship.img_name).upgrades.filter(
                        (upg) => upg.img_name === upgId
                      )[0].img_name,
                    },
                  ],
                }
          ),
        ],
      });
  }
  function addShip(ship) {
    setFleet({
      ...fleet,
      ships: [
        ...fleet.ships,
        {
          img_name: ship.img_name,
          upgs: [],
        },
      ],
    });
  }
  function sumShipValues(array, value) {
    console.log(array
      .map(
        (ship) =>
          getShip(ship.img_name)[value] || 0))
    return array
    .map(
      (ship) =>
        (getShip(ship.img_name)[value] || 0) +
        ship?.upgs
          .map((upg) => (getUpgrade(getShip(ship.img_name), upg.img_name)[value] || 0)
          )
          .reduce((a, c) => a + c, 0)
    )
    .reduce((a, c) => a + c, 0)
  }
  function getShip(imgName) {
    return ships[fleet.race].filter(
      (raceShip) => raceShip.img_name === imgName
    )[0];
  }
  function getUpgrade(ship, imgName) {
    return ship.upgrades.filter((shipUpg) => shipUpg.img_name === imgName)[0];
  }
  return (
    <div className="ShipContainer fleet">
      <Cards races={races} selectedRace={fleet.race} onClick={selectRace} />
      {!!fleet.race && <ShipsInner raceId={fleet.race} onClick={addShip} />}
      {!!fleet.race && (
        <div className="cardContainer">
          <div className={fleet.race}>
            <input
              type="text"
              value={fleet.name}
              onChange={(e) => changeName(e)}
            />
            <input
              type="number"
              value={fleet.maxPoints}
              onChange={(e) => changeMax(e)}
            />
            /
            {
              sumShipValues(fleet.ships, 'cost')
            }
            {" "}Transport capacity: {
              sumShipValues(fleet.ships, 'capacity')
            }/{
              sumShipValues(fleet.ships.filter(ship=>getShip(ship.img_name).class === 'unit'), 'size')
            }
          </div>
          {fleet.ships.map((ship, i) => (
            <div className="shipCardsInFleet">
              <UnitCard
                key={i}
                ship={getShip(ship.img_name)}
                zIndex={ship.upgs?.length}
                isMainCard={true}
                shipId={i}
                upgAdd={addUpg}
              >
                <div className="deleteCard" onClick={() => removeCard(i)}>
                  X
                </div>
              </UnitCard>
              {ship.upgs?.map((upg, j) => (
                <UpgradeCard
                  key={j}
                  parent={getShip(ship.img_name)}
                  upgrade={getUpgrade(getShip(ship.img_name), upg.img_name)}
                  fleetView={false}
                  left={j === 0 ? 157 : 157 + j * 266}
                  zIndex={ship.upgs?.length - (j + 1)}
                >
                  <div
                    className="deleteCard"
                    onClick={() => removeUpgCard(i, j)}
                  >
                    X
                  </div>
                </UpgradeCard>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Fleet;
