import "./ships.css";
import { useParams } from "react-router-dom";
import Cards from "../cards/cards";
import ShipsInner from "./shipsInner";

function Ships({ races }) {
  let { raceId } = useParams();
  return (
    <>
      <Cards races={races} selectedRace={raceId} />
      {raceId && (
          <ShipsInner raceId={raceId} />
      )}
    </>
  );
}

export default Ships;
