import "./ship.css";
import terranShips from "../../mockups/terranShips.json";
import zergShips from "../../mockups/zergShips.json";
import protossShips from "../../mockups/protossShips.json";
import ShipCard from "../shipCard/shipCard";
import UpgradeCard from "../UpgradeCard/UpgradeCard";
import ShipBase from "../shipBase/shipBase";
import { useParams } from "react-router-dom";
import Ships from "../ships/ships";

function Ship({ races }) {
  const ships = {
    protoss: protossShips,
    terran: terranShips,
    zerg: zergShips,
  };
  let { raceId, shipId } = useParams();
  const selectedShip = ships[raceId].filter(
    (ship) => ship.img_name === shipId
  )[0];
  return (
    <>
      <Ships races={races}></Ships>
      <div className="cardContainer">
        <div className="title">Ship / Unit Cards</div>
        <div className="selectedShip">
          <ShipCard ship={selectedShip} />
        </div>
        {selectedShip.upgrades?.length && (
          <>
            <div className="title">Upgrade Cards</div>
            <div className="selectedShip">
              {selectedShip.upgrades?.map((upgrade, key) => (
                <UpgradeCard
                  key={key}
                  parent={selectedShip}
                  upgrade={upgrade}
                />
              ))}
            </div>
          </>
        )}
        {selectedShip.class === "ship" && (
          <>
            <div className="title">Base and Dial</div>
            <ShipBase ship={selectedShip} />
          </>
        )}
      </div>
    </>
  );
}

export default Ship;
