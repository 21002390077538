import terranShips from "../mockups/terranShips.json";
import zergShips from "../mockups/zergShips.json";
import protossShips from "../mockups/protossShips.json";
import React from "react";
export const races = ["protoss", "terran", "zerg"];
export const ships = {
    protoss: protossShips,
    terran: terranShips,
    zerg: zergShips
};
  
  export const ShipContext = React.createContext(
    races,
    ships
  );