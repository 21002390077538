import Image from "../common/image";
import "./ShipActions.css";

function ShipActions({ action }) {
  return (
    <div className={`shipAction`}>
      <Image
        itemCass={`${action.name} shipActionIcon`}
        alt={`${action.name} action`}
        src={require(`../../pics/${action.name}_${action.difficulty}_ic.png`)}
      />
      {action.link && (
        <ShipActions action={action.link} />
      )}
    </div>
  );
}

export default ShipActions;
