import Image from "../common/image";

function CardBack({ race, itemClass }) {
  return (
    <div id="Cover" className={itemClass}>
      {race !== "random" ? 
      <Image src={require(`../../pics/${race}_logo.jpg`)} /> : 
      <div>?</div>
      }
    </div>
  );
}

export default CardBack;
