import Image from "../common/image";

function ShipType({ types }) {
  return (
    <div className="shipType">
      {types.map((type, i) => (
        <Image
          key={i}
          itemCass="shipTypeIcon"
          src={require(`../../pics/${type}_ic.png`)}
        />
      ))}
    </div>
  );
}

export default ShipType;
