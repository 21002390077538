import ShipDial from "../ShipDial/shipDial";
import Image from "../common/image";
import ShipActions from "../shipActions/shipActions";
import ShipFooter from "../shipFooter/shipFooter";
import ShipHeader from "../shipHeader/shipHeader";
import ShipPoints from "../shipPoints/shipPoints";

function UnitCard({ ship, isMainCard = false, zIndex=0 , upgAdd=()=>{}, shipId=0,children}) {
  const shipPoints = Object.keys(ship.points).map((key) => (
    <ShipPoints key={key} name={key} point={ship.points[key]} />
  ));
  return (
    <div
      id={isMainCard ? "shipCard" : "pilotCard"}
      className={`shipCard ${ship.race}`}
      style={{zIndex: zIndex}}
    >
      <ShipHeader ship={ship} />
      <div className={`shipMain ${isMainCard && "shipMainCard"}`}>
        <div className="shipName">{ship.name}</div>
        <div className="shipDesc">
          {!isMainCard && ship.description}
          {!isMainCard && ship.ability && (
            <div className="shipAbility">
              <hr />
              <div>{ship.ability}</div>
            </div>
          )}
          {isMainCard && ship.maneuvers && <ShipDial ship={ship} />}
        </div>
        <div className="shipPoints">
          {isMainCard
            ? ship.upgrades &&
              ship.upgrades.map((upgrade, k) => (
                <div key={k} className="ship_upg" onClick={()=>upgAdd(shipId, upgrade.img_name)}>
                  <Image
                    src={require(`../../pics/${upgrade.img_name}_ic.png`)}
                  />
                </div>
              ))
            : shipPoints}
        </div>
        {!isMainCard && (
          <div className="shipActions">
            {ship.actionsNum > 1 && <div className="actionsNum">2x</div>}
            {ship.actions.map((action, i) => (
              <ShipActions key={i} action={action} />
            ))}
          </div>
        )}
      </div>
      <ShipFooter ship={ship} />
      {children && children}
    </div>
  );
}

export default UnitCard;
