import Token from "../tokens/tokens";
import "./shipCard.css";
import Image from "../common/image";
import UnitCard from "../unitCard/unitCard";

function ShipCard({ ship }) {
  return (
    <div className={`shipCards ${ship.race}`}>
      <UnitCard ship={ship} />
      {ship.tokens && <Token tokens={ship.tokens} />}
      {ship.template && (
        <Image
          itemCass={ship.template.img}
          src={require(`../../pics/${ship.template.img}_template.png`)}
        />
      )}
      <UnitCard ship={ship} isMainCard={true} />
      {/*<CardBack itemClass={`shipCard ${ship.race} Cover`} race={ship.race} />*/}
    </div>
  );
}

export default ShipCard;
