import { Link, useLocation } from "react-router-dom";
import './header.css';

function Header(){
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return <div className="App-header">
    <Link className={splitLocation[1] === "cards" ? "active" : ""} to="/">Cards</Link>
    <Link className={splitLocation[1] === "fleets" ? "active" : ""} to="/fleets">Fleets</Link>
  </div>
}

export default Header;