import Image from "../common/image";

function RoundDial({ ship, maneuvers = [], isCover = false }) {
  return (
    <div className={`BaseDial ${isCover ? "Cover" : ""}`}>
      {maneuvers.map((maneuver, i) => (
        <div
          key={i}
          className="dialManeuver"
          style={{ rotate: `${(360 / maneuvers.length) * i}deg` }}
        >
          <Image
            src={require(`../../pics/${maneuver.m.type}_${
              maneuver.m.difficulty
            }_${maneuver.m.side ? maneuver.m.side : ""}_ic.png`)}
          />
          <br />
          {maneuver.k}
        </div>
      ))}
      {isCover ? (
        <div>
          <Image
            itemCass="racePic"
            src={require(`../../pics/${ship.race}_logo.jpg`)}
          />
          <div className="name">{ship.name}</div>
        </div>
      ) : (
        <div className="shipIcon">
          <Image src={require(`../../pics/${ship.img_name}_ic.png`)} />
        </div>
      )}
    </div>
  );
}

export default RoundDial;
