import "./ships.css";
import terranShips from "../../mockups/terranShips.json";
import zergShips from "../../mockups/zergShips.json";
import protossShips from "../../mockups/protossShips.json";
import Image from "../common/image";
import { Link } from "react-router-dom";

function ShipsInner({ raceId, onClick }) {
  const ships = {
    protoss: protossShips,
    terran: terranShips,
    zerg: zergShips,
  };
  return (
    <div className="ships">
      {ships[raceId].map((ship, i) => (
        typeof onClick === "function" ? (
            <div key={i} onClick={() => onClick(ship)}>
            <div className={`shipCard ${ship.race}`}>
              <div className="shipHead">
                <Image
                  src={require(`../../pics/${ship.img_name}_siluette.png`)}
                  itemCass="shipPic"
                />
              </div>
              <div className="shipName">{ship.name}</div>
            </div>
            </div>
          ) : (
        <Link key={i}  to={`/cards/${raceId}/${ship.img_name}`}>
          <div className={`shipCard ${ship.race}`}>
            <div className="shipHead">
              <Image
                src={require(`../../pics/${ship.img_name}_siluette.png`)}
                itemCass="shipPic"
              />
            </div>
            <div className="shipName">{ship.name}</div>
          </div>
        </Link>
        ))
      )}
    </div>
  );
}

export default ShipsInner;
