import Image from "../common/image";

function ShipFooter({ ship }) {
  return (
    <div className="shipFooter">
      <div className="shipIcon">
        <Image src={require(`../../pics/${ship.img_name}_ic.png`)} />
      </div>
      <div className="shipExtras">
        {ship.detectorRange && (
          <div className="shipDetector">
            <Image src={require(`../../pics/detector_ic.png`)} /> range{" "}
            {ship.detectorRange}
          </div>
        )}
        {ship.capacity && (
          <div className="shipTransport">
            <Image src={require(`../../pics/transport_ic.png`)} /> capacity{" "}
            {ship.capacity}
          </div>
        )}
        {ship.commandRange && (
          <div className="shipCommand">
            <Image src={require(`../../pics/coordinate_white_ic.png`)} /> range{" "}
            {ship.commandRange}
          </div>
        )}
        {ship.cloakRange && (
          <div className="cloakRange">
            <Image src={require(`../../pics/cloakingField_ic.png`)} /> range{" "}
            {ship.cloakRange}
          </div>
        )}
        {ship.class === "unit" && (
          <div className="unitSize">
            <Image src={require(`../../pics/${ship.race}.png`)} /> {ship.size}
          </div>
        )}
      </div>
      <div className="shipCost">{ship.cost}</div>
    </div>
  );
}

export default ShipFooter;
