import Image from "../common/image";
import "./token.css";

function Token({ tokens }) {
  return tokens.map((token, i) => (
    <div className={`token ${token.size}`}>
      <Image key={i} src={require(`../../pics/${token.img}.png`)} />
    </div>
  ));
}

export default Token;
