import { Link } from "react-router-dom";
import CardBack from "../cardBack/cardBack";
import "./fleets.css";

function Fleets({fleets}) {
  return (
    <div className="fleets cardList">
      {fleets.map((fleet) => (
        <Link key={fleet.name} to={`/fleet/${fleet.name}`}>
        <div className="fleet selectCard">
          <CardBack
            itemClass={`shipCard ${fleet.race} Cover`}
            race={fleet.race}
          />
          <div className="title">{fleet.name}</div>
          <div className="points">
            {fleet.maxPoints} / {fleet.points}
          </div>
        </div>
        </Link>
      ))}
      <Link to={`/fleet/new`}>
      <div className="fleet selectCard">
        <CardBack itemClass={`shipCard random Cover`} race={"random"} />
        new fleet
      </div>
      </Link>
    </div>
  );
}

export default Fleets;
