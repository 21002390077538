import "./cards.css";
import CardBack from "../cardBack/cardBack";
import { Link } from "react-router-dom";

function Cards({ races, selectedRace, onClick }) {
  return (
    <div className={`cardList`}>
      {races.map((race) => (
        <div
          key={race}
          className={`selectCard ${selectedRace === race && "selected"}`}
        >
          {typeof onClick === "function" ? (
            <div onClick={() => onClick(race)}>
              <CardBack itemClass={`shipCard ${race} Cover`} race={race} />
              {race}
            </div>
          ) : (
            <Link to={`/cards/${race.toLowerCase()}`}>
              <CardBack itemClass={`shipCard ${race} Cover`} race={race} />
              {race}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
}

export default Cards;
