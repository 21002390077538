import Image from "../common/image";
import "./shipDial.css";

function ShipDial({ ship }) {
  return (
    <div className={`ShipDial ${ship.race}`}>
      {Object.keys(ship.maneuvers)
        .toReversed()
        .map((key) => (
          <div key={key} className="dialSpeed">
            {ship.maneuvers[key].map((maneuver) => (
              <div
                key={`${maneuver.type}_${maneuver.difficulty}_${maneuver.side}`}
                className={`dialManeuver `}
              >
                <Image
                  src={require(`../../pics/${maneuver.type}_${
                    maneuver.difficulty
                  }_${maneuver.side ? maneuver.side : ""}_ic.png`)}
                />
              </div>
            ))}
          </div>
        ))}
    </div>
  );
}

export default ShipDial;
