import ShipType from "../ShipType/ShipType";
import Image from "../common/image";

function ShipHeader({ ship }) {
  return (
    <div className="shipHead">
      <Image
        src={require(`../../pics/${ship.img_name}_siluette.png`)}
        itemCass="shipPic"
      />
      {ship.type && <ShipType types={ship.type} />}
    </div>
  );
}

export default ShipHeader;
