import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Navigate,
} from "react-router-dom";
import Fleets from "./components/fleets/fleets";
import Cards from "./components/cards/cards";
import Header from "./components/header/header";
import { races } from "./contexts/ships";
import Ships from "./components/ships/ships";
import Ship from "./components/ship/ship";
import Fleet from "./components/fleet/fleet";
import fleets from "./mockups/fleets.json";


function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <div className="App-content">
          <div className={`ShipContainer`}>
            <Routes>
            <Route
              path="/"
              element={<Cards races={races} selectedRace={null} />}
            ></Route>
              <Route
                path="/cards"
                element={<Cards races={races} selectedRace={null} />}
              ></Route>
              <Route
                path="/cards/:raceId"
                element={<Ships races={races}></Ships>}
              ></Route>
              <Route
                path="/cards/:raceId/:shipId"
                element={<Ship races={races}></Ship>}
              ></Route>
              <Route exact path="/fleets" element={<Fleets fleets={fleets}/>} />
              <Route exact path="/fleet/:fleetName" element={<Fleet fleets={fleets} races={races}/>} />
              {/*<Route exact path="/" element={<Navigate to="/cards" />} />*/}
            </Routes>
          </div>
        </div>
      </Router>
      <div className="App-footer">Footer</div>
    </div>
  );
}

export default App;
