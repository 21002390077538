import Image from "../common/image";
import "./shipPoints.css";

function ShipPoints({ name, point }) {
  return (
    <div className={`shipPoint ${name}`}>
      <div className="icon">
        {name !== "interceptor" ? (
          <div className="iconInner"></div>
        ) : (
          <Image src={require(`../../pics/release_ic_white_ic.png`)} />
        )}
      </div>
      <div className="amount">
        {point?.point ? point.point : point}
        {point?.up && <div className="up"></div>}
        {point?.down && <div className="down"></div>}
      </div>
    </div>
  );
}

export default ShipPoints;
