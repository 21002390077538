import BaseCard from "../baseCard/baseCard";
import RoundDial from "../roundDial/roundDial";
import "./shipBase.css";

function ShipBase({ ship }) {
  let maneuvers = [];
  ship.maneuvers &&
    Object.keys(ship.maneuvers)
      .toReversed()
      .map((key) =>
        ship.maneuvers[key].map((maneuver) =>
          maneuvers.push({ m: maneuver, k: key })
        )
      );

  return (
    <div className="bases">
      <BaseCard ship={ship} />
      {maneuvers.length ? (
        <>
          <RoundDial maneuvers={maneuvers} ship={ship} />
          <RoundDial isCover={true} ship={ship} />
        </>
      ) : null}
    </div>
  );
}

export default ShipBase;
