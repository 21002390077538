import Image from "../common/image";
import ShipActions from "../shipActions/shipActions";
import ShipPoints from "../shipPoints/shipPoints";
import Token from "../tokens/tokens";
import "./UpgradeCard.css";

function UpgradeCard({ parent, upgrade, fleetView = true, left = 0,zIndex=0, children }) {
  const upgradePoints = upgrade?.plus?.points ? (
    Object.keys(upgrade.plus.points).map((key) => (
      <ShipPoints key={key} name={key} point={upgrade.plus.points[key]} />
    ))
  ) : (
    <span></span>
  );

  return (
    <div className={`upg_token ${parent.race}`} style={{left: `${left}px`, zIndex:zIndex}}>
      <div className={`upgradeCard ${parent.race}`}>
        <div className="upgradeSide">
          <Image
            src={require(`../../pics/${parent.img_name}_siluette.png`)}
            itemCass="parentPic"
          />
          <div className="parentName">{parent.name}</div>
        </div>
        <div className="upgradeMain">
          <div className="upgradePic">
            <Image src={require(`../../pics/${upgrade.img_name}_ic.png`)} />
          </div>
          <div className="upgradePlusses">
            {upgrade.plus?.points && upgradePoints}
            {upgrade.plus?.actions?.map((action, i) => (
              <ShipActions key={i} action={action} />
            ))}
          </div>
          <div className="upgradeName">{upgrade.name}</div>
          <div className="upgradeDesc">
            {upgrade.description}
            {upgrade.ability && (
              <div className="upgradeAbility">
                <hr />
                <div>{upgrade.ability}</div>
              </div>
            )}
          </div>
          <div className="upgradeFooter">
            <div className="upgradeExtras">
              {upgrade.plus?.detector && (
                <div className="shipDetector">
                  <Image src={require(`../../pics/detector_ic.png`)} /> range{" "}
                  {upgrade.plus.detector}
                </div>
              )}
              {upgrade.plus?.transport && (
                <div className="shipTransport">
                  <Image src={require(`../../pics/transport_ic.png`)} />{" "}
                  capacity {upgrade.plus.transport}
                </div>
              )}
              {upgrade.plus?.commandRange && (
                <div className="shipCommand">
                  <Image src={require(`../../pics/coordinate_white_ic.png`)} />{" "}
                  range {upgrade.plus.commandRange}
                </div>
              )}
            </div>
            <div className="upgradeCost">{upgrade.cost}</div>
          </div>
        </div>
      </div>
      {(upgrade.tokens && fleetView) && <Token tokens={upgrade.tokens} />}
      {(upgrade.template && fleetView) && (
        <Image
          itemCass={upgrade.template.img}
          src={require(`../../pics/${upgrade.template.img}_template.png`)}
        />
      )}
      {children && children}
    </div>
  );
}

export default UpgradeCard;
